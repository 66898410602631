import React, { useMemo, useReducer } from "react";
import { DumpData } from "../../utils/types";
import {
    RESET_PROGRESS_BARS,
    SET_ACTIVE_DALLAS_SENSOR_VALUES,
    SET_ACTIVE_LLS_SENSOR_VALUES,
    SET_DALLAS_SENSOR_DATA,
    SET_DUMP_READING_DATA,
    SET_LLS_SENSOR_DATA,
    SET_PROGRESS_BAR,
    SET_UPDATE_FIRMWARE_MODAL_INFO,
} from "./deviceStatusActions";
import DeviceStatusContext, { DeviceStatusContextState } from "./deviceStatusContext";
import DeviceStatusReducer from "./deviceStatusReducer";
import { FirmwareType } from "../../constants/constants";

export interface DeviceStatusStateProps {
    children: React.ReactNode;
}

export interface InitialState {
    dumpData: {
        percents: number;
        currentFile: number;
        filesCount: number;
    };
    dallasSensorData: string[];
    llsSensorData: string[];
    activeDallasSensorValues: string[];
    activeLlsSensorValues: string[];
    progressBars: { [key: string]: number };
    updateFirmwareModalInfo: {
        isOpen: boolean;
        path: string;
        step: number;
        newVersion: string;
        firmwareType: FirmwareType;
        currentVersion: string;
        error: string;
        stepWithError?: number;
    };
}

const DeviceStatusState = ({ children }: DeviceStatusStateProps) => {
    const initialState = {
        dumpData: { percents: 0, currentFile: 0, filesCount: 0 },
        dallasSensorData: [],
        llsSensorData: [],
        activeDallasSensorValues: [],
        activeLlsSensorValues: [],
        progressBars: {},
        updateFirmwareModalInfo: {
            isOpen: false,
            path: "",
            step: 0,
            newVersion: "",
            firmwareType: FirmwareType.None,
            currentVersion: "",
            error: "",
            stepWithError: -1,
        },
    };

    const [state, dispatch] = useReducer(DeviceStatusReducer, initialState);

    const setDumpReadingData = (data: DumpData) => {
        dispatch({
            type: SET_DUMP_READING_DATA,
            payload: data,
        });
    };
    const setDallasSensorData = (payload: string[]): void =>
        dispatch({ type: SET_DALLAS_SENSOR_DATA, payload });

    const setLlsSensorData = (payload: string[]): void =>
        dispatch({ type: SET_LLS_SENSOR_DATA, payload });

    const setActiveDallasSensorValue = (payload: string): void =>
        dispatch({ type: SET_ACTIVE_DALLAS_SENSOR_VALUES, payload });

    const setActiveLlsSensorValue = (payload: string): void =>
        dispatch({ type: SET_ACTIVE_LLS_SENSOR_VALUES, payload });

    const setProgressBar = (data: {
        progressBarName: string;
        completedPercent: number;
    }) =>
        dispatch({
            type: SET_PROGRESS_BAR,
            payload: data,
        });

    const setUpdateFirmwareModalInfo = (data: {
        isOpen: boolean;
        path: string;
        step: number;
        newVersion: string;
        firmwareType: FirmwareType;
        currentVersion: string;
        error: string;
    }) =>
        dispatch({
            type: SET_UPDATE_FIRMWARE_MODAL_INFO,
            payload: data,
        });

    const resetProgressBars = () => {
        dispatch({
            type: RESET_PROGRESS_BARS,
        });
    };
    const contextValue = useMemo(() => {
        return {
            dumpData: state.dumpData,
            dallasSensorData: state.dallasSensorData,
            llsSensorData: state.llsSensorData,
            activeLlsSensorValues: state.activeLlsSensorValues,
            activeDallasSensorValues: state.activeDallasSensorValues,
            progressBars: state.progressBars,
            updateFirmwareModalInfo: state.updateFirmwareModalInfo,
            setDumpReadingData,
            setDallasSensorData,
            setLlsSensorData,
            setActiveDallasSensorValue,
            setActiveLlsSensorValue,
            setProgressBar,
            setUpdateFirmwareModalInfo,
            resetProgressBars,
        } as DeviceStatusContextState;
    }, [state]);

    return (
        <DeviceStatusContext.Provider value={contextValue}>
            {children}
        </DeviceStatusContext.Provider>
    );
};

export default DeviceStatusState;
