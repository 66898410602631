/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, Fragment } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import FooterSkeleton from "./FooterSkeleton";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";

export interface DesktopSkeletonProps {
    rows: number;
}

const DesktopSkeleton = ({ rows }: DesktopSkeletonProps) => {
    const {
        colors: { white, gray200, gray100, blue400, blue200, blue100 },
    } = useContext(ThemeContext);

    const SingleOfflineDevice = () => {
        return (
            <TableRow>
                <TableCell>
                    <Skeleton width="100%" height={72} />
                </TableCell>

                <TableCell>
                    <Skeleton width="100%" height={20} />
                </TableCell>

                <TableCell>
                    <div
                        css={css`
                            display: flex;

                            span + span {
                                margin-left: 16px;
                            }
                        `}
                    >
                        <Skeleton width={83} height={32} />
                        <Skeleton width={83} height={32} />
                        <Skeleton width={83} height={32} />
                    </div>
                </TableCell>

                <TableCell>
                    <Skeleton
                        width="100%"
                        height={36}
                        css={css`
                            background: ${gray100};
                        `}
                    />
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Fragment>
            <TableContainer
                css={css`
                    max-height: 780px;

                    th,
                    td {
                        border-bottom: none;
                    }

                    th {
                        padding: 12px;
                        background: ${blue100};
                        border-bottom: none;
                        box-shadow: inset 0px 1px 0px ${blue200},
                            inset 0px -1px 0px ${blue200};
                    }

                    th:first-of-type {
                        padding: 12px 12px 12px 24px;
                    }

                    th:last-of-type {
                        padding: 12px 24px 12px 12px;
                    }

                    td {
                        padding: 10px 12px;
                        box-shadow: inset 0px -1px 0px ${gray200};
                    }

                    td:nth-of-type(1) {
                        width: 102px;
                        padding: 10px 12px 10px 24px;
                    }

                    td:nth-of-type(2) {
                        width: 166px;
                    }

                    td:nth-of-type(4) {
                        width: 119px;
                        padding: 10px 24px 10px 12px;
                    }

                    tbody {
                        tr:last-of-type {
                            td {
                                box-shadow: none;
                            }
                        }
                    }

                    ::-webkit-scrollbar {
                        width: 18px;
                        height: 18px;
                    }

                    ::-webkit-scrollbar-button:vertical:start {
                        background-color: ${blue100};
                        box-shadow: inset 0px 1px 0px ${blue200},
                            inset 0px -1px 0px ${blue200};
                        height: 44px;
                    }

                    ::-webkit-scrollbar-thumb {
                        background-color: ${blue200};
                        border-radius: 100px;
                        border: 5px solid ${white};
                    }

                    ::-webkit-scrollbar-thumb:hover {
                        background-color: ${blue400};
                    }

                    @media (max-width: 1439px) {
                        td:nth-of-type(2) {
                            width: 89px;
                        }
                    }
                `}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Skeleton width={43} height={20} />
                            </TableCell>

                            <TableCell>
                                <Skeleton width={56} height={20} />
                            </TableCell>

                            <TableCell>
                                <Skeleton width={78} height={20} />
                            </TableCell>

                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Array(rows)
                            .fill(0)
                            .map((row, i) => (
                                <SingleOfflineDevice key={i} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <FooterSkeleton />
        </Fragment>
    );
};

export default DesktopSkeleton;
