import { AxiosResponse } from "axios";
import { ErrorDetails } from "./types";
import { polygonMaxVerticles } from "../constants/constants";
import { ListItemInterface } from "../components/Editor/MenuItemView/Segment/Component/List/List";
import { ErrorsObj } from "../context/list/listReducer";

export const convertMinToSeconds = (minutes: number) => minutes * 60;

export const getUniqueListBy = (arr: any[], key: string) => {
    return [...(new Map(arr.map((item) => [item[key], item])).values() as any)];
};

export const findInArrayByIndex = (array: any[], index: number): any =>
    array.find((item) => item.index === index);

export const removeDuplicateObjectsByParameterId = (array: any[]) => {
    let uniq: any = {};
    return array.filter(
        (obj) => !uniq[obj.parameterId] && (uniq[obj.parameterId] = true)
    );
};

export const findSameObjectIndex = (array: any[], object: any) =>
    array.findIndex((item) => item.id === object.id);

export const convertIntToTimeString = (timeInt: number) => {
    const hours = Math.floor(timeInt / 60);
    const minutes = timeInt % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
    }`;
};

export const convertTimeFromStringToInt = (timeString: string) => {
    const formatTimeStringToMinutes =
        Number(timeString.split(":")[0]) * 60 +
        Number(timeString.split(":")[1]);

    return formatTimeStringToMinutes;
};

export const convertSecondsToMinutes = (seconds: number) => {
    return Math.floor(seconds / 60);
};

export const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop);

export const scrollToComponentRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - window.innerHeight / 2);

export const noop = () => {
    // This is intentional
};

export const getIdTooltipText = (parameterId: number, avlId: number): string =>
    avlId === 0 ? `ID: ${parameterId}` : `ID: ${parameterId}, AVL ID:${avlId}`;

export const convertPathToTwoBackslashes = (path: string) => {
    if (!path) {
        return path;
    }
    return path.replace(/\\/g, "\\\\");
};

export const convertUnixTimeToDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
};

export const convertBytesToKilobytes = (bytes: number) => {
    return Math.round(bytes / 1024) + " kB";
};

export const findSubStringInString = (string: string, subString: string) => {
    let index = string.indexOf(subString);
    if (index === -1) {
        return false;
    }
    return true;
};

export const preventENotation = (e: React.KeyboardEvent<HTMLInputElement>) =>
    ["e", "E", "+"].includes(e.key) && e.preventDefault();

export const getSumOfObjectValues = (obj: any, index: number) => {
    let sum = 0;
    for (let i = 0; i < index; i++) {
        sum += obj[i];
    }
    return sum;
};

/**
 * Checks if iterable is not empty.
 */
export function isNotEmptyIterable<T>(iterable: Iterable<T>): boolean {
    // Could not find a better working solution
    return (iterable as any)?.props?.children?.length > 0;
}

export const addOrRemoveStringFromArray = (arr: string[], str: string) => {
    if (arr.includes(str)) {
        return arr.filter((item) => item !== str);
    } else {
        return [...arr, str];
    }
};

export const getFileNameFromPath = (path: string) => {
    if (!path) return "";
    const split = path.split(/.*[/|\\]/);
    return split.length === 1 ? split[0] : split[1];
};
export const formatArrayIntoOneArrayWithComponents = (array: any[]) => {
    const oldArray = [...array];
    let newArray: any[] = [];

    oldArray.forEach((item) => {
        if (item.hasOwnProperty("components")) {
            let tempObject: { [key: string]: any } = {};
            item.components.forEach((component: any) => {
                tempObject[component.localizationKey] = component;
            });
            newArray.push(tempObject);
        } else {
            newArray.push(item);
        }
    });
    return newArray;
};

export const returnProvidedNumberOfItemsOfArray = (
    array: any[],
    numberOfItems: number
) => {
    const newArray = [...array];
    return newArray.slice(0, numberOfItems);
};

export const formatCoordinatesIntoMultiArrayBasedOnType = (
    obj: { x: string; y: string }[],
    type: number
) => {
    const result: any = [];
    const newArray = [...obj];
    if (type === 1) {
        result.push([Number(newArray[0].y), Number(newArray[0].x)]);
    } else if (type === 2) {
        result.push(
            [Number(newArray[0].y), Number(newArray[0].x)],
            [Number(newArray[1].y), Number(newArray[1].x)]
        );
    } else {
        newArray.forEach((item) => {
            Number(item.y) !== 0 &&
                Number(item.x) !== 0 &&
                result.push([Number(item.y), Number(item.x)]);
        });
    }
    return result?.length ? result : [[0, 0]];
};

export const generateMockOfflineDevice = () => {
    const mockDevice = {
        id: 1,
        version: "9.8.0.0", // NOSONAR  S1313: Using hardcoded IP addresses is security-sensitive Justification: This is not an IP address
        title: "CFG for FW 03.28.07++",
        fmType: "FMB003",
        menuItems: [
            "Mobile network",
            "System",
            "Tracking settings",
            "Features",
            "SMS / call settings",
            "Input / output (I/O)",
            "Bluetooth",
            "Accessories",
        ],
        warnings: [],
        initialValues: {
            visibilities: [
                {
                    parameterId: 67100,
                    isDisabled: true,
                },
                {
                    parameterId: 67101,
                    isDisabled: true,
                },
                {
                    parameterId: 2027,
                    isDisabled: true,
                },
                {
                    parameterId: 2028,
                    isDisabled: true,
                },
                {
                    parameterId: 2029,
                    isDisabled: true,
                },
                {
                    parameterId: 19001,
                    isDisabled: false,
                },
                {
                    parameterId: 19002,
                    isDisabled: false,
                },
                {
                    parameterId: 10100,
                    isDisabled: true,
                },
                {
                    parameterId: 10105,
                    isDisabled: true,
                },
                {
                    parameterId: 10104,
                    isDisabled: true,
                },
                {
                    parameterId: 10150,
                    isDisabled: true,
                },
                {
                    parameterId: 10151,
                    isDisabled: true,
                },
                {
                    parameterId: 10152,
                    isDisabled: true,
                },
                {
                    parameterId: 10153,
                    isDisabled: true,
                },
                {
                    parameterId: 10155,
                    isDisabled: true,
                },
                {
                    parameterId: 10154,
                    isDisabled: true,
                },
                {
                    parameterId: 11011,
                    isDisabled: true,
                },
                {
                    parameterId: 11015,
                    isDisabled: true,
                },
                {
                    parameterId: 11849,
                    isDisabled: true,
                },
                {
                    parameterId: 11811,
                    isDisabled: true,
                },
                {
                    parameterId: 11813,
                    isDisabled: true,
                },
                {
                    parameterId: 11815,
                    isDisabled: true,
                },
                {
                    parameterId: 11816,
                    isDisabled: true,
                },
                {
                    parameterId: 11814,
                    isDisabled: true,
                },
                {
                    parameterId: 7000,
                    isDisabled: true,
                },
                {
                    parameterId: 7003,
                    isDisabled: true,
                },
                {
                    parameterId: 7004,
                    isDisabled: true,
                },
                {
                    parameterId: 7005,
                    isDisabled: true,
                },
                {
                    parameterId: 7006,
                    isDisabled: true,
                },
                {
                    parameterId: 7007,
                    isDisabled: true,
                },
                {
                    parameterId: 7008,
                    isDisabled: true,
                },
                {
                    parameterId: 7010,
                    isDisabled: true,
                },
                {
                    parameterId: 7011,
                    isDisabled: true,
                },
                {
                    parameterId: 7022,
                    isDisabled: true,
                },
                {
                    parameterId: 7023,
                    isDisabled: true,
                },
                {
                    parameterId: 7024,
                    isDisabled: true,
                },
                {
                    parameterId: 7223,
                    isDisabled: true,
                },
                {
                    parameterId: 7224,
                    isDisabled: true,
                },
                {
                    parameterId: 7225,
                    isDisabled: true,
                },
                {
                    parameterId: 7226,
                    isDisabled: true,
                },
                {
                    parameterId: 7227,
                    isDisabled: true,
                },
                {
                    parameterId: 7228,
                    isDisabled: true,
                },
                {
                    parameterId: 7229,
                    isDisabled: true,
                },
                {
                    parameterId: 7230,
                    isDisabled: true,
                },
                {
                    parameterId: 7231,
                    isDisabled: true,
                },
                {
                    parameterId: 7232,
                    isDisabled: true,
                },
                {
                    parameterId: 7233,
                    isDisabled: true,
                },
                {
                    parameterId: 7234,
                    isDisabled: true,
                },
                {
                    parameterId: 7015,
                    isDisabled: true,
                },
                {
                    parameterId: 7250,
                    isDisabled: true,
                },
                {
                    parameterId: 7247,
                    isDisabled: true,
                },
                {
                    parameterId: 7376,
                    isDisabled: true,
                },
                {
                    parameterId: 7286,
                    isDisabled: true,
                },
                {
                    parameterId: 7220,
                    isDisabled: true,
                },
                {
                    parameterId: 7221,
                    isDisabled: true,
                },
                {
                    parameterId: 7021,
                    isDisabled: true,
                },
                {
                    parameterId: 7020,
                    isDisabled: true,
                },
                {
                    parameterId: 7069,
                    isDisabled: true,
                },
                {
                    parameterId: 7322,
                    isDisabled: true,
                },
                {
                    parameterId: 7393,
                    isDisabled: true,
                },
                {
                    parameterId: 7394,
                    isDisabled: true,
                },
                {
                    parameterId: 7395,
                    isDisabled: true,
                },
                {
                    parameterId: 7396,
                    isDisabled: true,
                },
                {
                    parameterId: 7323,
                    isDisabled: true,
                },
                {
                    parameterId: 7397,
                    isDisabled: true,
                },
                {
                    parameterId: 7398,
                    isDisabled: true,
                },
                {
                    parameterId: 7399,
                    isDisabled: true,
                },
                {
                    parameterId: 7400,
                    isDisabled: true,
                },
                {
                    parameterId: 7324,
                    isDisabled: true,
                },
                {
                    parameterId: 7401,
                    isDisabled: true,
                },
                {
                    parameterId: 7402,
                    isDisabled: true,
                },
                {
                    parameterId: 7403,
                    isDisabled: true,
                },
                {
                    parameterId: 7404,
                    isDisabled: true,
                },
                {
                    parameterId: 7325,
                    isDisabled: true,
                },
                {
                    parameterId: 7405,
                    isDisabled: true,
                },
                {
                    parameterId: 7406,
                    isDisabled: true,
                },
                {
                    parameterId: 7407,
                    isDisabled: true,
                },
                {
                    parameterId: 7408,
                    isDisabled: true,
                },
                {
                    parameterId: 7253,
                    isDisabled: true,
                },
                {
                    parameterId: 7256,
                    isDisabled: true,
                },
                {
                    parameterId: 7259,
                    isDisabled: true,
                },
                {
                    parameterId: 7262,
                    isDisabled: true,
                },
                {
                    parameterId: 7326,
                    isDisabled: true,
                },
                {
                    parameterId: 7327,
                    isDisabled: true,
                },
                {
                    parameterId: 7328,
                    isDisabled: true,
                },
                {
                    parameterId: 7329,
                    isDisabled: true,
                },
                {
                    parameterId: 7290,
                    isDisabled: true,
                },
                {
                    parameterId: 7291,
                    isDisabled: true,
                },
                {
                    parameterId: 7292,
                    isDisabled: true,
                },
                {
                    parameterId: 7293,
                    isDisabled: true,
                },
                {
                    parameterId: 7381,
                    isDisabled: true,
                },
                {
                    parameterId: 7382,
                    isDisabled: true,
                },
                {
                    parameterId: 7383,
                    isDisabled: true,
                },
                {
                    parameterId: 7384,
                    isDisabled: true,
                },
                {
                    parameterId: 7385,
                    isDisabled: true,
                },
                {
                    parameterId: 7386,
                    isDisabled: true,
                },
                {
                    parameterId: 7387,
                    isDisabled: true,
                },
                {
                    parameterId: 7388,
                    isDisabled: true,
                },
                {
                    parameterId: 7389,
                    isDisabled: true,
                },
                {
                    parameterId: 7390,
                    isDisabled: true,
                },
                {
                    parameterId: 7391,
                    isDisabled: true,
                },
                {
                    parameterId: 7392,
                    isDisabled: true,
                },
                {
                    parameterId: 7001,
                    isDisabled: true,
                },
                {
                    parameterId: 7002,
                    isDisabled: true,
                },
                {
                    parameterId: 7009,
                    isDisabled: true,
                },
                {
                    parameterId: 7014,
                    isDisabled: true,
                },
                {
                    parameterId: 7016,
                    isDisabled: true,
                },
                {
                    parameterId: 7012,
                    isDisabled: true,
                },
                {
                    parameterId: 7013,
                    isDisabled: true,
                },
                {
                    parameterId: 7243,
                    isDisabled: true,
                },
                {
                    parameterId: 7419,
                    isDisabled: true,
                },
                {
                    parameterId: 7038,
                    isDisabled: true,
                },
                {
                    parameterId: 7039,
                    isDisabled: true,
                },
                {
                    parameterId: 7633,
                    isDisabled: true,
                },
                {
                    parameterId: 7040,
                    isDisabled: true,
                },
                {
                    parameterId: 7041,
                    isDisabled: true,
                },
                {
                    parameterId: 7042,
                    isDisabled: true,
                },
                {
                    parameterId: 7043,
                    isDisabled: true,
                },
                {
                    parameterId: 7044,
                    isDisabled: true,
                },
                {
                    parameterId: 7045,
                    isDisabled: true,
                },
                {
                    parameterId: 7046,
                    isDisabled: true,
                },
                {
                    parameterId: 7047,
                    isDisabled: true,
                },
                {
                    parameterId: 7048,
                    isDisabled: true,
                },
                {
                    parameterId: 7049,
                    isDisabled: true,
                },
                {
                    parameterId: 7050,
                    isDisabled: true,
                },
                {
                    parameterId: 7051,
                    isDisabled: true,
                },
                {
                    parameterId: 7052,
                    isDisabled: true,
                },
                {
                    parameterId: 7053,
                    isDisabled: true,
                },
                {
                    parameterId: 7054,
                    isDisabled: true,
                },
                {
                    parameterId: 7055,
                    isDisabled: true,
                },
                {
                    parameterId: 7056,
                    isDisabled: true,
                },
                {
                    parameterId: 7057,
                    isDisabled: true,
                },
                {
                    parameterId: 7058,
                    isDisabled: true,
                },
                {
                    parameterId: 7059,
                    isDisabled: true,
                },
                {
                    parameterId: 7060,
                    isDisabled: true,
                },
                {
                    parameterId: 7061,
                    isDisabled: true,
                },
                {
                    parameterId: 7062,
                    isDisabled: true,
                },
                {
                    parameterId: 7063,
                    isDisabled: true,
                },
                {
                    parameterId: 7064,
                    isDisabled: true,
                },
                {
                    parameterId: 7065,
                    isDisabled: true,
                },
                {
                    parameterId: 7067,
                    isDisabled: true,
                },
                {
                    parameterId: 7068,
                    isDisabled: true,
                },
                {
                    parameterId: 7241,
                    isDisabled: true,
                },
                {
                    parameterId: 7264,
                    isDisabled: true,
                },
                {
                    parameterId: 7524,
                    isDisabled: true,
                },
                {
                    parameterId: 7525,
                    isDisabled: true,
                },
                {
                    parameterId: 7526,
                    isDisabled: true,
                },
                {
                    parameterId: 7527,
                    isDisabled: true,
                },
                {
                    parameterId: 7066,
                    isDisabled: true,
                },
                {
                    parameterId: 7522,
                    isDisabled: true,
                },
                {
                    parameterId: 7529,
                    isDisabled: true,
                },
                {
                    parameterId: 11400,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11000,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11200,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11800,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11850,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11100,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11500,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11300,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11310,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 11600,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 20000,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 50000,
                    collection: {
                        "3": true,
                    },
                    isDisabled: false,
                },
                {
                    parameterId: 256,
                    isDisabled: true,
                },
                {
                    parameterId: 257,
                    isDisabled: true,
                },
                {
                    parameterId: 300,
                    isDisabled: true,
                },
                {
                    parameterId: 40003,
                    isDisabled: true,
                },
            ],
            bindableLists: [
                {
                    listName: "SMS Events Numbers",
                    items: [
                        {
                            index: 6000,
                            value: "",
                        },
                        {
                            index: 6001,
                            value: "",
                        },
                        {
                            index: 6002,
                            value: "",
                        },
                        {
                            index: 6003,
                            value: "",
                        },
                        {
                            index: 6004,
                            value: "",
                        },
                        {
                            index: 6005,
                            value: "",
                        },
                        {
                            index: 6006,
                            value: "",
                        },
                        {
                            index: 6007,
                            value: "",
                        },
                        {
                            index: 6008,
                            value: "",
                        },
                        {
                            index: 6009,
                            value: "",
                        },
                    ],
                },
            ],
        },
    };
    return mockDevice;
};

export const roundToSixDecimals = (num: number) => {
    return Math.round(1000000 * num) / 1000000;
};

export const getParameterIdsAndValuesFromLayout = (
    object: any,
    uniqueTag?: string
) => {
    let result: any = [];
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            if (key === "parameterId") {
                result.push({
                    id: object[key],
                    value: object["parameterValue"],
                    oldValue: object["parameterValue"],
                    error: "",
                    ...(uniqueTag && { uniqueTag }),
                });
            } else if (typeof object[key] === "object") {
                result = result.concat(
                    getParameterIdsAndValuesFromLayout(object[key], uniqueTag)
                );
            }
        }
    }
    return result.filter((item: any) => item.value !== undefined);
};

export const convertAxiosErrorToErrorDetails = (
    err: AxiosResponse<{ message: string }>
): ErrorDetails => {
    const error = err as any;
    let messageTitle;
    let messageDescription;

    if (error.response) {
        messageTitle =
            error.response.data.title ||
            error.response.data ||
            error.response.statusText;
        messageDescription = error.response.data.detail || messageTitle;
    } else {
        if (error.request) {
            if (error.request.statusText) {
                messageTitle = error.request.statusText;
            } else {
                messageTitle = error.message;
            }
        } else {
            messageTitle = error.message;
        }
        messageDescription = messageTitle;
    }

    return { title: messageTitle, description: messageDescription };
};

export const bitsToNumber = (bits: any[]) => {
    let result = 0;
    for (let i = 0; i < 8; i++) {
        result += bits[i] * Math.pow(2, 7 - i);
    }
    return result;
};

export const numberToBits = (number: number) => {
    let bits = [];

    // convert the number to binary
    while (number > 0) {
        bits.unshift(number % 2);
        number = Math.floor(number / 2);
    }

    // pad the array with zeroes if necessary
    while (bits.length < 8) {
        bits.unshift(0);
    }
    return bits;
};

export const decimalCount = (num: number | undefined) => {
    // Convert to String
    if (!num) {
        return 0;
    }
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options as any);
};

export const fakeArray: any = (length: number) => {
    const arr = [];
    for (let i = 0; i < length; i++) {
        arr.push({
            value: `value${i}`,
            isInvisible: false,
            disabledTooltip: `disabledTooltip${i}`,
            text: `text${i}`,
        });
    }
    return arr;
};

export const findMissingGeozoneIndex = (geozones: any[]): number => {
    const indices = new Set<number>();

    for (const geozone of geozones) {
        indices.add(geozone.index);
    }

    for (let i = 0; i < geozones.length; i++) {
        if (!indices.has(i)) {
            return i;
        }
    }
    // If no missing index found, return the next index
    return geozones.length;
};

// write typings
export const extractIdAndValueFromObj = (obj: any) => {
    const result: { id: string; value: string }[] = [];
    obj?.rows?.forEach((row: any) => {
        row.components.forEach((component: any) => {
            result.push({
                id: component.parameterId,
                value: component.parameterValue,
            });
        });
    });
    return result;
};

export const mergeFirstArrayWithSecond = (
    ovj: { id: string; value: string }[],
    ovj2: { id: string; value: string }[][]
) => {
    const ovj2Map = ovj2.reduce((acc: any, item: any) => {
        acc[item.id] = item.value;
        return acc;
    }, {});
    return ovj.map((item) => {
        const value = ovj2Map[item.id];
        return value ? { ...item, value } : item;
    });
};

export const addOrReplaceObjectInArray = (array: any[], newObj: any): any[] => {
    const index = array.findIndex((item) => item.id === newObj.id);

    if (index === -1) {
        // If the object with the same ID doesn't exist, add the new object to the array
        return [...array, newObj];
    } else {
        // If the object with the same ID exists, replace it with the new object
        const newArray = [...array];
        newArray[index] = newObj;
        return newArray;
    }
};

export const findBiggestId = (objects: any[]): number | null => {
    if (objects.length === 0) {
        return null;
    }

    let biggestId = objects[0].id;
    for (const obj of objects) {
        if (obj.id > biggestId) {
            biggestId = obj.id;
        }
    }

    return biggestId;
};

export const formatCoordinatesBasedOnGeozoneShape = (geozone: any) => {
    const latitude =
        geozone.type === 1
            ? [
                  roundToSixDecimals(geozone.coordinates[0][0]),
                  ...Array(polygonMaxVerticles).fill(0),
              ]
            : [
                  ...geozone.coordinates.map((item: number[]) =>
                      roundToSixDecimals(item[0])
                  ),
                  ...Array(polygonMaxVerticles).fill(0),
              ];

    const longitude =
        geozone.type === 1
            ? [
                  roundToSixDecimals(geozone.coordinates[0][1]),
                  ...Array(polygonMaxVerticles).fill(0),
              ]
            : [
                  ...geozone.coordinates.map((item: number[]) =>
                      roundToSixDecimals(item[1])
                  ),
                  ...Array(polygonMaxVerticles).fill(0),
              ];

    return {
        latitude,
        longitude,
    };
};

export const replacePlaceholders = (
    str: string,
    arg1: number | string,
    arg2: number | string,
    arg3?: number | string
): string => {
    return str
        .replace("{0}", String(arg1))
        .replace("{1}", String(arg2))
        .replace("{2}", String(arg3));
};

export const getParametersFromLayout = async (allData: any[]) => {
    const parameters: { [key: string]: any } = {};

    const fillParametersObj = (
        componentType: string,
        parameterId: number,
        parameterValue: any,
        listConfig: any = null
    ) => {
        if (componentType === "List" && listConfig) {
            parameters[listConfig.name] = listConfig.items.some(
                ({ value }: { value: string }) => value.trim().length > 0
            );
        } else {
            parameterId !== 0 && (parameters[parameterId] = parameterValue);
        }
    };

    const getBlocksParamaters = (blocks: any) => {
        blocks.forEach(({ segments, switchId, switchValue }: any) => {
            switchValue && fillParametersObj("Switch", switchId, switchValue);

            segments.forEach(
                ({
                    component,
                    groupBox,
                    ecoCalculator,
                    ioItem,
                    passwordBox,
                    canItem,
                }: any) => {
                    if (component) {
                        fillParametersObj(
                            component.componentType,
                            component.parameterId,
                            component.parameterValue,
                            component.listConfig
                        );
                    } else if (groupBox) {
                        groupBox.switchValue &&
                            fillParametersObj(
                                "Switch",
                                groupBox.switchId,
                                groupBox.switchValue
                            );
                        groupBox.components.forEach((component: any) =>
                            fillParametersObj(
                                component.componentType,
                                component.parameterId,
                                component.parameterValue,
                                component.listConfig
                            )
                        );
                    } else if (ecoCalculator) {
                        ecoCalculator.components.forEach((component: any) =>
                            fillParametersObj(
                                component.componentType,
                                component.parameterId,
                                component.parameterValue,
                                component.listConfig
                            )
                        );
                    } else if (ioItem) {
                        return Object.entries(ioItem).map((item: any) => {
                            if (item[1]?.parameterId) {
                                fillParametersObj(
                                    item[1].componentType,
                                    item[1].parameterId,
                                    item[1].parameterValue
                                );
                            }
                            return null; // Placeholder return statement
                        });
                    } else if (canItem) {
                        return Object.entries(canItem).map((item: any) => {
                            if (item[1]?.parameterId) {
                                fillParametersObj(
                                    item[1].componentType,
                                    item[1].parameterId,
                                    item[1].parameterValue
                                );
                            }
                            return null; // Placeholder return statement
                        });
                    } else if (passwordBox) {
                        fillParametersObj(
                            passwordBox.component.componentType,
                            passwordBox.component.parameterId,
                            passwordBox.component.parameterValue
                        );
                    }
                    return;
                }
            );
        });
    };

    allData.forEach(({ blocks, switchId, switchValue, modal }: any) => {
        switchValue && fillParametersObj("Switch", switchId, switchValue);
        modal && getBlocksParamaters(modal.blocks);
        getBlocksParamaters(blocks);
    });

    return parameters;
};

export const createListErrors = (
    availableInputs: ListItemInterface[],
    maxLength: number
) => {
    const errorsObj: ErrorsObj = availableInputs.reduce((previous, current) => {
        const errorValue =
            current.value.length > maxLength
                ? `This input supports a maximum length of ${maxLength} characters.`
                : null;

        return { ...previous, [current.index]: errorValue };
    }, {});

    return errorsObj;
};

export const getLayoutParameterValuesFromWasmContext = (
    obj: any,
    wasmParameters?: any
): any => {
    if (Array.isArray(obj)) {
        return obj.map((item) =>
            getLayoutParameterValuesFromWasmContext(item, wasmParameters)
        );
    } else if (typeof obj === "object" && obj !== null) {
        for (let key in obj) {
            if (key === "parameterId" && obj.hasOwnProperty("parameterValue")) {
                obj["parameterValue"] = wasmParameters[obj["parameterId"]];
            }
            if (key === "parameterId" && obj.hasOwnProperty("value")) {
                obj["value"] = wasmParameters[obj["parameterId"]];
            }
            obj[key] = getLayoutParameterValuesFromWasmContext(
                obj[key],
                wasmParameters
            );
        }
    }
    return obj;
};

export const isNullOrUndefined = (obj: any): boolean =>
    obj === undefined || obj === null;
export const createReactTableColumn = (
    id: string,
    accessor: string,
    Header: string,
    filter: any,
    Cell: any,
    minWidth?: number
) => {
    return {
        id,
        accessor,
        Header,
        filter,
        Cell,
        minWidth,
    };
};

export const findAdjacentItem = (array: string[], id: string | number) => {
    const index = array.findIndex((item) => item === id);
    if (index === -1) return undefined; // id not found in array

    if (index > 0) {
        // If there is a previous item, return it
        return array[index - 1];
    } else if (index < array.length - 1) {
        // If there is no previous item but there is a next item, return the next item
        return array[index + 1];
    }

    // If there is neither a previous item nor a next item, return undefined
    return undefined;
};

export const encodeQueryParams = (url: string) => {
    if (!url) {
        return url;
    }

    const amp = "&";
    const quot = "?";
    const eq = "=";

    const urlSegments = url.split(quot);
    const origin = urlSegments[0] + (urlSegments.length > 1 ? quot : "");
    let result = [];

    for (const param of urlSegments[urlSegments.length - 1]
        .split(amp)
        .map((pair) => pair.split(eq))) {
        result.push({
            key: param[0],
            value: encodeURIComponent(param.slice(1).join(eq)),
        });
    }

    return (
        origin +
        result.map((x) => x.value && `${x.key}${eq}${x.value}`).join(amp)
    );
};

export const extractEXIMFile = (path: string) => {
    if (path.length > 1000) {
        return "";
    }
    if (path?.toLowerCase().endsWith(".e.xim")) {
        const fileName = path.split(/[/\\]/).pop();
        return fileName ? fileName : "";
    }
    return "";
};
