import { FirmwareType } from "../../constants/constants";
import { addOrRemoveStringFromArray } from "../../utils/helpers";
import {
    RESET_PROGRESS_BARS,
    SET_ACTIVE_DALLAS_SENSOR_VALUES,
    SET_ACTIVE_LLS_SENSOR_VALUES,
    SET_DALLAS_SENSOR_DATA,
    SET_DUMP_READING_DATA,
    SET_LLS_SENSOR_DATA,
    SET_PROGRESS_BAR,
    SET_UPDATE_FIRMWARE_MODAL_INFO,
} from "./deviceStatusActions";
import { InitialState } from "./DeviceStatusState";

type Action =
    | { type: typeof SET_DUMP_READING_DATA; payload: any }
    | { type: typeof SET_DALLAS_SENSOR_DATA; payload: string[] }
    | { type: typeof SET_LLS_SENSOR_DATA; payload: string[] }
    | { type: typeof SET_ACTIVE_DALLAS_SENSOR_VALUES; payload: string }
    | { type: typeof SET_ACTIVE_LLS_SENSOR_VALUES; payload: string }
    | {
          type: typeof SET_PROGRESS_BAR;
          payload: {
              progressBarName: string;
              completedPercent: number;
          };
      }
    | {
          type: typeof SET_UPDATE_FIRMWARE_MODAL_INFO;
          payload: {
              isOpen: boolean;
              path: string;
              step: number;
              newVersion: string;
              firmwareType: FirmwareType;
              currentVersion: string;
              error: string;
              stepWithError?: number;
          };
      }
    | {
          type: typeof RESET_PROGRESS_BARS;
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_UPDATE_FIRMWARE_MODAL_INFO:
            return {
                ...state,
                updateFirmwareModalInfo: action.payload,
            };
        case SET_DUMP_READING_DATA:
            return {
                ...state,
                dumpData: {
                    ...state.dumpData,
                    ...action.payload,
                },
            };
        case SET_PROGRESS_BAR:
            return {
                ...state,
                progressBars: {
                    ...state.progressBars,
                    [action.payload.progressBarName]:
                        action.payload.completedPercent,
                },
            };
        case SET_DALLAS_SENSOR_DATA:
            return {
                ...state,
                dallasSensorData: action.payload,
            };
        case SET_LLS_SENSOR_DATA:
            return {
                ...state,
                llsSensorData: action.payload,
            };
        case SET_ACTIVE_LLS_SENSOR_VALUES: {
            return {
                ...state,
                activeLlsSensorValues: addOrRemoveStringFromArray(
                    state.activeLlsSensorValues,
                    action.payload
                ),
            };
        }
        case SET_ACTIVE_DALLAS_SENSOR_VALUES: {
            return {
                ...state,
                activeDallasSensorValues: addOrRemoveStringFromArray(
                    state.activeDallasSensorValues,
                    action.payload
                ),
            };
        }
        case RESET_PROGRESS_BARS: {
            return {
                ...state,
                progressBars: {},
            };
        }
        default:
            return state;
    }
};
