/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect, useState } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import useApi from "../../../../utils/useApi";
import Button from "../../../MuiComponents/Button";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import AppTypeContext from "../../../../context/appType/appTypeContext";
import useNavigation from "../../../../utils/useNavigation";
import AlertContext from "../../../../context/alert/alertContext";
import ConfirmModal from "../../../MuiComponents/Modals/ConfirmModal";
import useUploadFile from "../../../../utils/useUploadFile";
import { getFileNameFromPath } from "../../../../utils/helpers";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import {
    AlertStatus,
    FROM_FILE_LOAD_STATUS,
} from "../../../../constants/constants";
import SettingsContext from "../../../../context/settings/settingsContext";
import useTct from "../../../../utils/useTct";

const UploadFiles = () => {
    const {
        colors: { white, gray100 },
    } = useContext(ThemeContext);

    const showLoadFromDevice = false;
    const { selectedLanguage, t } = useContext(LanguageContext);

    const {
        layoutData,
        actionLoading,
        setUploadedFileDataToLayout,
        uploadFileLoadStatus,
        uploadFileName,
        setUploadFileName,
        setUploadFileLoadStatus,
    } = useContext(LayoutContext);
    const { isEditor } = useContext(AppTypeContext);
    const { setDisabledParameters } = useContext(MenuItemContext);

    const { isDesktopApplication } = useContext(UserInfoContext);
    const { setAlert } = useContext(AlertContext);
    const { setNotification } = useContext(SettingsContext);

    const { postData, showErrorMsg } = useApi();
    const { chechFileCompatibilityAsync } = useTct();
    const { refreshCurrentPage } = useNavigation();

    const { inputProps, selectedFile, openFileDialog } = useUploadFile({
        accept: ".cfg, .config",
    });

    const getLoadStatus = () =>
        layoutData?.fromFile
            ? FROM_FILE_LOAD_STATUS
            : layoutData?.settings.deviceSettings[0].loadStatus;

    const getFileName = () =>
        layoutData?.fromFile
            ? getFileNameFromPath(layoutData?.path as string)
            : layoutData?.settings.deviceSettings[0].fileName;

    useEffect(() => {
        setUploadFileLoadStatus(getLoadStatus());
        setUploadFileName(getFileName());
    }, [layoutData]);

    const [
        fileCompatibilityErrorDescription,
        setFileCompatibilityErrorDescription,
    ] = useState("");
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);
    const onConfirmationModalClose = () => setIsConfirmationModalOpen(false);
    const onConfirmationModalSubmit = () => {
        setIsConfirmationModalOpen(false);
        uploadFile();
    };

    const uploadFile = async () => {
        try {
            const formData = new FormData();
            selectedFile && formData.append("file", selectedFile);

            const { data } = await postData(
                `${selectedLanguage}/editor/${layoutData?.id}/parameters/loadfrompath`,
                formData
            );
            data?.visibilities && setDisabledParameters(data.visibilities);

            setUploadFileLoadStatus(FROM_FILE_LOAD_STATUS);

            setUploadFileName(selectedFile?.name as string);
            setUploadedFileDataToLayout({
                fromFile: true,
                path: (selectedFile as any)?.path,
            });

            refreshCurrentPage();

            if (data.alerts && data.alerts.length > 0) {
                const alert = data.alerts[0];
                setNotification(alert.type, alert.title, alert.description);
                setAlert(alert.type, alert.title, alert.description);
            } else {
                setAlert(AlertStatus.Success, t.ParametersLoadedFile);
            }
        } catch (error) {
            console.log(error);
            showErrorMsg(error as any);
        }
    };
    const checkFileCompatibility = async () => {
        try {
            const formData = new FormData();
            selectedFile && formData.append("file", selectedFile);
            await chechFileCompatibilityAsync(layoutData?.id || 0, formData);

            uploadFile();
        } catch (error) {
            if ((error as any).response.status === 409) {
                setFileCompatibilityErrorDescription(
                    (error as any).response.data.detail
                );
                setIsConfirmationModalOpen(true);
                return;
            }
            console.log(error);
            showErrorMsg(error as any);
        }
    };
    useEffect(() => {
        if (selectedFile) {
            checkFileCompatibility();
        }
    }, [selectedFile]);

    const renderConfigurationName = () => {
        if (!isDesktopApplication && isEditor) {
            return layoutData?.fileName;
        }
        if (uploadFileLoadStatus === FROM_FILE_LOAD_STATUS) {
            return uploadFileName;
        }
        return t[uploadFileLoadStatus];
    };

    return (
        <Fragment>
            <div>
                <div
                    css={css({
                        color: gray100,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.16px",
                        paddingLeft: "8px",
                        wordBreak: "break-word",
                    })}
                >
                    {t.Configuration}:
                </div>

                <div
                    css={css({
                        color: white,
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        paddingLeft: "8px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflowX: "hidden",
                        margin: "12px 0",
                    })}
                >
                    {renderConfigurationName()}
                </div>

                {isDesktopApplication && (
                    <Fragment>
                        <Button
                            fullWidth
                            variant="textOnly"
                            size="small"
                            color="transparent"
                            disabled={actionLoading}
                            onClick={openFileDialog}
                            css={css({
                                wordBreak: "break-word",
                            })}
                            idForTesting="uploadFileButton"
                        >
                            <span>{"Upload file (.cfg)"}</span>
                        </Button>
                        <input {...inputProps} />
                    </Fragment>
                )}

                {showLoadFromDevice && layoutData?.isOnline && (
                    <Button
                        fullWidth
                        variant="textOnly"
                        size="small"
                        color="transparent"
                        disabled={actionLoading}
                        css={css({
                            wordBreak: "break-word",
                            marginTop: "4px",
                        })}
                        idForTesting="loadFromDeviceButton"
                    >
                        {t.LoadFromDevice}
                    </Button>
                )}
            </div>
            <ConfirmModal
                open={isConfirmationModalOpen}
                close={onConfirmationModalClose}
                submit={onConfirmationModalSubmit}
                title={t.ConfigurationMismatch}
                description={fileCompatibilityErrorDescription}
                primaryBtnLabel={t.Continue}
                secondaryBtnLabel={t.Cancel}
            />
        </Fragment>
    );
};

export default UploadFiles;
