import { useContext, useRef } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource, } from "axios";
import AlertContext from "../context/alert/alertContext";
import SettingsContext from "../context/settings/settingsContext";
import { ConfigurationPasswordState, DeviceFamily } from "./types";
import { convertAxiosErrorToErrorDetails, encodeQueryParams } from "./helpers";
import ErrorContext from "../context/error/errorContext";
import AuthenticationContext from "../context/authentication/authenticationContext";
import { Tct } from "./useTct";
import LanguageContext from "../context/language/languageContext";
import { GeneratedLayout, MenuItem } from "../generatedTypes";
import { SelectedFile } from "./useUploadFile";
import { AlertStatus, JwtTokenKey } from "../constants/constants";

export interface TctApi extends Tct {
    axios: any;
    cancelSource: CancelTokenSource;
    controller: AbortController;
    isCanceled: (payload: any) => boolean;
    isAxiosError: (payload: any) => boolean;
    getData: (
        resource: string,
        config?: AxiosRequestConfig
    ) => Promise<AxiosResponse<any>>;
    updateParameters: (id: any, payload: any) => Promise<AxiosResponse<any>>;
    updateData: (resource: string, payload: any) => Promise<AxiosResponse<any>>;
    postData: (
        resource: string,
        payload?: any,
        config?: AxiosRequestConfig
    ) => Promise<AxiosResponse<any>>;
    deleteData: (resource: string) => Promise<AxiosResponse<any>>;
    disconnectFromDevice: (
        selectedLanguage: string,
        ignoreFailedRequest: boolean
    ) => Promise<void>;
}

const useApi = (): TctApi => {
    const { setAlert } = useContext(AlertContext);
    const { setNotification } = useContext(SettingsContext);
    const { setError } = useContext(ErrorContext);
    const { isAuthenticated, setAuthenticated } = useContext(
        AuthenticationContext
    );
    const { selectedLanguage } = useContext(LanguageContext);

    const authenticatePromise = useRef<Promise<AxiosResponse> | null>(null);

    const controller = new AbortController();
    const CancelToken = axios.CancelToken;
    const cancelSource = CancelToken.source();
    const isCanceled = axios.isCancel;
    const isAxiosError: (payload: any) => boolean = axios.isAxiosError;

    const api = axios.create({
        baseURL: "/api/",
        cancelToken: cancelSource.token,
        signal: controller.signal,
        headers: {
            "Content-Type": "application/json",
        },
    });

    const getData = async (resource: string, config?: AxiosRequestConfig) => {
        if (!isAuthenticated) {
            await authenticate();
        }

        const encodedResource = encodeQueryParams(resource);
        return api.get(encodedResource, config);
    };
    const removeQueryParams = () => {
        const newUrl = window.location.href
            .replace(/[?&]?id=\d+/, "")
            .replace(/[?&]?token=.+#/, "#");

        if (window.history.pushState) {
            window.history.pushState(null, "", newUrl);
        } else {
            window.history.replaceState(null, "", newUrl);
        }
    };
    const getTokenFromQuery = (): string | null => {
        const match = window.location.search.match(/token=([^#&\/]+)&?#?\/?/);
        if (!match) {
            return "";
        }
        removeQueryParams();

        return match.length === 2 ? match[1] : "";
    };

    const setJwtToApiRequests = (jwt: string) => {
        const authHeaderKey: any = "Authorization";
        // Set global default for all instances
        // @ts-ignore TS2542: Index signature in type String only permits reading. (justification: this is how axios documentation shows how to set headers (https://github.com/axios/axios?tab=readme-ov-file#config-defaults))
        axios.defaults.headers!.common[authHeaderKey] = `Bearer ${jwt}`;
        // Set local default for this instance
        // @ts-ignore TS2542: Index signature in type String only permits reading. (justification: this is how axios documentation shows how to set headers)
        api.defaults.headers!.common[authHeaderKey] = `Bearer ${jwt}`;
    };

    const authenticate = async () => {
        if (authenticatePromise.current) {
            const authenticateResult = await authenticatePromise.current;
            if (authenticateResult.status !== 200) {
                throw new Error("Authentication failed");
            }
            return;
        }

        try {
            const jwt = sessionStorage.getItem(JwtTokenKey);
            const fotaToken = getTokenFromQuery();
            // If jwt already exists for the session (most likely a page reload) use the existing jwt
            if (jwt) {
                setJwtToApiRequests(jwt);
            }

            const authenticateRequestPromise = api.get(
                "authenticate?token=" + fotaToken
            );
            authenticatePromise.current = authenticateRequestPromise;

            const response = await authenticateRequestPromise;
            sessionStorage.setItem(JwtTokenKey, response.data);
            setJwtToApiRequests(response.data);

            setAuthenticated(true);
            return;
        } catch (error) {
            setError(convertAxiosErrorToErrorDetails(error as any));
        }

        setAuthenticated(false);
        throw new Error("Authentication failed");
    };

    const updateParameters = async (id: any, payload: any) => {
        if (!isAuthenticated) {
            await authenticate();
        }
        return api.patch(`parameters/${id}`, payload);
    };

    const updateData = async (resource: string, payload: any) => {
        if (!isAuthenticated) {
            await authenticate();
        }
        return api.put(resource, payload);
    };

    const postData = async (
        resource: string,
        payload?: any,
        config?: AxiosRequestConfig
    ) => {
        if (!isAuthenticated) {
            await authenticate();
        }
        return api.post(resource, payload, config);
    };

    const deleteData = async (resource: string) => {
        if (!isAuthenticated) {
            await authenticate();
        }
        return api.delete(resource);
    };

    const disconnectFromDevice = async (
        selectedLanguage: string,
        ignoreFailedRequest: boolean
    ) => {
        try {
            await postData(`${selectedLanguage}/device/disconnect`);
        } catch (error) {
            if (!ignoreFailedRequest) {
                throw error;
            }
        }
    };

    const showErrorMsg = (
        err: AxiosResponse<{ message: string }>,
        customErrorTitle?: string,
        customErrorDescription?: string
    ) => {
        const errorDetails = convertAxiosErrorToErrorDetails(err);

        console.error(err);
        setAlert(
            AlertStatus.Critical,
            errorDetails.title || customErrorTitle,
            errorDetails.description || customErrorDescription
        );
        setNotification(
            "error",
            errorDetails.title || customErrorTitle || "",
            errorDetails.description || customErrorDescription || ""
        );
    };

    const getOfflineConfigurationAsync = async (
        hwVersion: string,
        specId: number = 1
    ): Promise<GeneratedLayout> => {
        const { data } = await postData(`${selectedLanguage}/editor/offline`, {
            hardwareVersion: hwVersion,
            specId: specId,
        });

        return data;
    };

    const getConfigurationFromFileAsync = async (
        selectedFile: File
    ): Promise<GeneratedLayout> => {
        const formData = new FormData();
        selectedFile && formData.append("file", selectedFile);
        const { data } = await postData(
            `${selectedLanguage}/editor/fromfile`,
            formData
        );

        return data;
    };

    const connectAsync = async (
        portName: string,
        connectionType: number
    ): Promise<GeneratedLayout> => {
        const { data } = await postData(`${selectedLanguage}/device/connect`, {
            port: portName,
            connectionType: Number(connectionType),
        });

        return data;
    };

    const reconnectAsync = async (): Promise<GeneratedLayout> => {
        const { data } = await getData(`${selectedLanguage}/device/reconnect`);

        return data;
    };

    const getConfigurationFromFotaAsync = async (
        fileId: number
    ): Promise<GeneratedLayout> => {
        const { data } = await getData(
            `${selectedLanguage}/fotaserver/openconfiguration?fileId=${fileId}`
        );

        return data;
    };

    const getMenuItemAsync = async (
        configurationId: number,
        menuItemId: number
    ): Promise<MenuItem> => {
        const { data } = await getData(
            `${selectedLanguage}/editor/menuitem/${menuItemId}?configurationId=${configurationId}`
        );

        return data;
    };

    const getFrameAsync = async (
        configurationId: number,
        menuItemId: number,
        frameId: number
    ) => {
        const { data } = await getData(
            `${selectedLanguage}/editor/menuitem/${menuItemId}/frame/${frameId}?configurationId=${configurationId}`
        );

        return data;
    };

    const getModifiedParameters = async (
        configurationId: number
    ): Promise<any> => {
        try {
            const { data } = await getData(
                `${selectedLanguage}/editor/${configurationId}/parameters/resolveChanged`
            );

            return data;
        } catch (error) {
            showErrorMsg(error as any);
        }
        return {
            parameters: [],
            recoveryConfigurationPasswordState: ConfigurationPasswordState.Same,
            configurationPasswordState: ConfigurationPasswordState.Same,
        };
    };

    const getDeviceGuideAsync = async (configurationId: number) => {
        const { data } = await getData(
            `${selectedLanguage}/deviceguide?configurationId=${configurationId}`
        );

        return data;
    };

    const setGuideStepAsCompletedAsync = async (
        configurationId: number,
        stepIndex: number
    ) => {
        await postData(
            `${selectedLanguage}/deviceguide/setascompleted?configurationId=${configurationId}&indexOfItem=${stepIndex}`
        );
    };

    const getUserInfoAsync = async () => {
        const { data } = await getData("userinfo");

        return data;
    };

    const getLanguagesAsync = async () => {
        const { data } = await getData("languages");

        return data;
    };

    const changeLanguageAsync = async (
        newLanguage: string,
        configurationId?: number
    ) => {
        const { data } = await getData(`${newLanguage}/startup/translations`, {
            params: {
                configurationId,
            },
        });

        return data;
    };

    const translateAsync = async (
        configurationId: number,
        keysToTranslate: string[],
        deviceFamily: DeviceFamily
    ) => {
        const { data } = await postData(
            `${selectedLanguage}/editor/${configurationId}/translate`,
            keysToTranslate
        );

        return data;
    };

    const saveToFileAsync = async (
        configurationId: number,
        filePath: string,
        modifiedOnly: boolean,
        skipPasswords: boolean
    ) => {
        await postData(
            `${selectedLanguage}/editor/${configurationId}/storetofile`,
            {
                filePath,
                modifiedOnly,
                skipPasswords,
            }
        );
    };

    const saveToFotaAsync = async (
        configurationId: number,
        fileName: string,
        modifiedOnly: boolean,
        skipPasswords: boolean
    ) => {
        await postData(
            `${selectedLanguage}/fotaserver/sendconfiguration?configurationId=${configurationId}`,
            {
                filePath: fileName,
                modifiedOnly: modifiedOnly,
                skipPasswords: skipPasswords,
            }
        );
    };

    const saveToDeviceAsync = async (
        configurationId: number,
        skipPasswords: boolean
    ) => {
        await postData(
            `${selectedLanguage}/device/save?configurationId=${configurationId}&savePassword=${!skipPasswords}`
        );
    };

    const getBluetoothViewAsync = async (configurationId: number) => {
        const { data } = await getData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/getbluetoothview`
        );

        return data;
    };

    const getBluetoothPresetListAsync = async (configurationId: number) => {
        const { data } = await getData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/getbluetoothpresetlist`
        );

        return data;
    };

    const getBluetoothTableAsync = async (
        configurationId: number,
        modalName: string,
        presetName?: string
    ) => {
        const presetNameQuery = presetName ? `&presetName=${presetName}` : "";
        const { data } = await getData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/getbluetoothtable?tableName=${modalName}${presetNameQuery}`
        );

        return data;
    };

    const getBluetoothModalDataAsync = async (
        configurationId: number,
        modalName: string,
        isEdit: boolean = false
    ) => {
        //TEST
        const { data } = await getData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/getbluetoothmodal?modalName=${modalName}&edit=${isEdit}`
        );

        return data;
    };

    const saveBluetoothPresetAsync = async (
        configurationId: number,
        tableName: string,
        presetName: string,
        parameters: any
    ) => {
        await postData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/saveBluetoothPreset?tableName=${tableName}${
                presetName ? `&presetName=${presetName}` : ""
            }`,
            parameters
        );
    };

    const updateSensorAsync = async (
        configurationId: number,
        modalName: string,
        parameters: any
    ) => {
        await postData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/updateSensor?modalName=${modalName}`,
            parameters
        );
    };

    const removeSensorAsync = async (
        configurationId: number,
        modalName: string,
        sensorNr: number
    ) => {
        await postData(
            `${selectedLanguage}/editor/${configurationId}/bluetooth/removeSensor?modalName=${modalName}&sensorNr=${sensorNr}`
        );
    };

    const searchAsync = async (
        configurationId: number,
        searchTerm: string,
        cancelToken?: CancelToken
    ) => {
        const { data } = await getData(
            `${selectedLanguage}/search?configurationId=${configurationId}&searchTerm=${searchTerm}`,
            { cancelToken: cancelToken }
        );

        return data;
    };

    const searchAvlIdsAsync = async (
        configurationId: number,
        searchTerm: string
    ) => {
        const { data } = await getData(
            `${selectedLanguage}/search/avlsearch?configurationId=${configurationId}&avlSearchTerm=${searchTerm}`
        );

        return data;
    };

    const getGeozoneTableAsync = async (configurationId: number) => {
        const { data } = await getData(
            `${selectedLanguage}/editor/geofencetable?configurationId=${configurationId}`
        );

        return data;
    };

    const getGeozoneAsync = async (
        configurationId: number,
        geozoneId: number,
        restoreDefaults: boolean = false
    ) => {
        const { data } = await getData(
            `${selectedLanguage}/editor/geozone/${geozoneId}?configurationId=${configurationId}&remove=${restoreDefaults}`
        );

        return data;
    };

    const getParameterTooltipAsync = async (
        configurationId: number,
        parameterId: number
    ) => {
        const { data } = await getData(
            `${selectedLanguage}/help/getparameterhelp/${configurationId}/${parameterId}`
        );

        return data;
    };

    const getBlockTooltipAsync = async (
        configurationId: number,
        blockName: string
    ) => {
        const { data } = await getData(
            `${selectedLanguage}/help/getblockhelp/${configurationId}/${blockName}`
        );

        return data;
    };

    const chechFileCompatibilityAsync = async (
        configurationId: number,
        formData: FormData
    ) => {
        await postData(
            `${selectedLanguage}/editor/${configurationId}/parameters/checkfilecompatibility`,
            formData
        );
    };

    const importCsvForListConfigAsync = async (
        configurationId: number,
        name: string,
        file: SelectedFile
    ) => {
        if (!file) {
            throw new Error("File not selected");
        }
        const formData = new FormData();
        formData.append("file", file);

        const { data } = await postData(
            `${selectedLanguage}/editor/${configurationId}/parameters/importcsvforlistconfig?listConfigName=${name}`,
            formData
        );
        return data;
    };

    const validateParameterAsync = async (
        configurationId: number,
        parameterId: number,
        value: string
    ) => {
        await postData(
            `${selectedLanguage}/editor/${configurationId}/parameters/${parameterId}/validate`,
            { value: value }
        );
    };

    const getSettingsAsync = async () => {
        const { data } = await getData(`${selectedLanguage}/settings`);
        return data;
    };

    const postSettingsAsync = async (settings: { [key: string]: any }) => {
        await postData(`${selectedLanguage}/settings`, settings);
    };

    const postSettingsHideOptionsAsync = async (settings: {
        [key: string]: any;
    }) => {
        await postData(`${selectedLanguage}/settings/hideoptions`, settings);
    };

    const getSystemInformationAsync = async () => {
        const { data } = await getData(
            `${selectedLanguage}/settings/systeminformation`
        );
        return data;
    };

    const getSettingsWithQueryParamsAsync = async (
        hwVersion: string,
        specId: number,
        isOnline: boolean
    ) => {
        const { data: settings } = await getData(
            `${selectedLanguage}/settings?hwVersion=${hwVersion}&specId=${specId}&online=${isOnline}`
        );
        return settings;
    };

    const getStartupDevicesAsync = async () => {
        const { data } = await getData(`${selectedLanguage}/startup/devices`);
        return data;
    };

    const getExperimentalConfigurationAsync = async () => {
        const { data: experimentalData } = await getData(
            `${selectedLanguage}/startup/experimentalConfigurations`
        );

        return experimentalData;
    };

    const postExternalConfigurationAsync = async (
        value: string | null,
        isDefault: boolean
    ) => {
        await postData(
            `${selectedLanguage}/startup/useExternalConfiguration?isDefault=${isDefault}`,
            value
        );
    };

    const updateParameterAsync = async (
        configurationId: number,
        parameterId: number,
        newValue: string | number,
        controllers: any,
        forceDependencyUpdate: boolean = false
    ) => {
        let url = `${selectedLanguage}/editor/${configurationId}/parameters/update?parameterId=${parameterId}&value=${newValue}`;
        if (forceDependencyUpdate) {
            url += "&forceDependencyUpdate=true";
        }

        const { data } = await postData(url, null, {
            signal: controllers[parameterId].signal,
        });
        return data;
    };

    const updateParametersAsync = async (
        configurationId: number,
        payload: { [key: string]: any },
        includeAllFails?: boolean,
        tryToFix?: boolean
    ) => {
        let url = `${selectedLanguage}/editor/${configurationId}/parameters/batchUpdate`;
        if (includeAllFails) {
            url += "?includeAllFails=true";
        }
        if (tryToFix) {
            url += "?tryToFix=true";
        }
        const { data } = await postData(url, payload);
        return data;
    };

    const getBluetoothDevicesAsync = async () => {
        const { data } = await getData(`${selectedLanguage}/device/bledevices`);

        return data;
    };

    const getBluetoothDevicesScanAsync = async () => {
        const { data } = await getData(
            `${selectedLanguage}/device/bledevices/scan`
        );
        return data;
    };

    const getSpecsAsync = async () => {
        const { data } = await getData(`${selectedLanguage}/specs`);
        
        return data;
    };
    
    const getDefaultSpecsAsync = async () => {
        const { data } = await getData(
            `${selectedLanguage}/specs/hwdefault`
        );
        
        return data;
    };

    return {
        axios,
        cancelSource,
        controller,
        isCanceled,
        isAxiosError,
        getData,
        updateParameters,
        updateData,
        postData,
        deleteData,
        disconnectFromDevice,

        showErrorMsg,

        getOfflineConfigurationAsync,
        getConfigurationFromFileAsync,
        connectAsync,
        reconnectAsync,
        getConfigurationFromFotaAsync,

        getMenuItemAsync,
        getFrameAsync,

        getModifiedParameters,

        getDeviceGuideAsync,
        setGuideStepAsCompletedAsync,

        getLanguagesAsync,
        changeLanguageAsync,
        translateAsync,

        getUserInfoAsync,

        saveToFileAsync,
        saveToFotaAsync,
        saveToDeviceAsync,

        getBluetoothViewAsync,
        getBluetoothPresetListAsync,
        getBluetoothTableAsync,
        saveBluetoothPresetAsync,
        updateSensorAsync,
        removeSensorAsync,

        searchAsync,
        searchAvlIdsAsync,

        getGeozoneTableAsync,
        getGeozoneAsync,

        getParameterTooltipAsync,
        getBlockTooltipAsync,
        getBluetoothModalDataAsync,

        chechFileCompatibilityAsync,
        importCsvForListConfigAsync,

        validateParameterAsync,

        postSettingsAsync,
        postSettingsHideOptionsAsync,
        getSettingsAsync,

        getSystemInformationAsync,
        getSettingsWithQueryParamsAsync,

        getStartupDevicesAsync,
        getExperimentalConfigurationAsync,

        postExternalConfigurationAsync,
        updateParameterAsync,
        updateParametersAsync,

        getBluetoothDevicesAsync,
        getBluetoothDevicesScanAsync,
        
        getSpecsAsync,
        getDefaultSpecsAsync,
    };
};

export default useApi;
export type { CancelTokenSource };
