import React, { useReducer } from "react";
import LanguageContext from "./languageContext";
import LanguageReducer from "./languageReducer";
import { defaultListLanguageFlag } from "../../constants/constants";
import {
    CHANGE_LANGUAGE,
    SET_INITIAL_LANGUAGES_DATA,
    SET_LOADING,
    SET_PREVIOUS_LIST_LANGUAGE,
} from "./languageActions";
import useTct from "../../utils/useTct";

interface LanguageStateProps {
    children: React.ReactNode;
}

const LanguageState = ({ children }: LanguageStateProps) => {
    const { changeLanguageAsync } = useTct();

    const initialState = {
        loading: false,
        selectedLanguage: "en",
        previousListLanguage: defaultListLanguageFlag,
        languages: [],
        translations: {},
    };

    const [state, dispatch] = useReducer(LanguageReducer, initialState);

    const setLoading = (val: boolean) =>
        dispatch({ type: SET_LOADING, payload: val });

    const setInitialLanguagesData = (data: any) =>
        dispatch({ type: SET_INITIAL_LANGUAGES_DATA, payload: data });

    const changeLanguage = async (newLang: string, configurationId: number) => {
        try {
            setLoading(true);

            const data = await changeLanguageAsync(newLang, configurationId);

            dispatch({
                type: CHANGE_LANGUAGE,
                payload: {
                    language: newLang,
                    translations: data,
                },
            });
        } catch (error) {
            setLoading(false);
        }
    };
    const setPreviousLanguage = (lang: string) => {
        dispatch({
            type: SET_PREVIOUS_LIST_LANGUAGE,
            payload: lang,
        });
    };
    return (
        <LanguageContext.Provider
            value={{
                loading: state.loading,
                selectedLanguage: state.selectedLanguage,
                languages: state.languages,
                previousListLanguage: state.previousListLanguage,
                t: state.translations,
                setInitialLanguagesData,
                setPreviousLanguage,
                changeLanguage,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageState;
