import React, { useContext, useMemo } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import Card from "../../../../MuiComponents/Card";
import Tabs from "../../../../MuiComponents/Tabs";
import Location from "./Location";
import GnssStatus from "./GnssStatus";
import { GnssLocationInformationData, GnssStatusInformationData } from "../../../../../generatedTypes";
import { SerializedStyles } from "@emotion/react/dist/emotion-react.cjs";

interface GnssInformationProps {
    locationData: GnssLocationInformationData;
    statusData: GnssStatusInformationData;
    keyBaseStyles: SerializedStyles;
    valueBaseStyles: SerializedStyles;
}

const GnssInformation = ({ locationData, statusData, keyBaseStyles, valueBaseStyles }: GnssInformationProps) => {
    const { t } = useContext(LanguageContext);

    const gnssTabs = useMemo(() => {
        const tabs = [];
        statusData && tabs.push({
            title: t.GnssStatus,
            content: <GnssStatus
                data={statusData}
                keyBaseStyles={keyBaseStyles}
                valueBaseStyles={valueBaseStyles}
            />,
        });
        locationData && tabs.push({
            title: t.Location,
            content: <Location
                data={locationData}
                keyBaseStyles={keyBaseStyles}
                valueBaseStyles={valueBaseStyles}
            />,
        });
        return tabs;
    }, [locationData, statusData]);

    return (
        <Card
            title={t.GnssInformation}
            description={t["GnssInformation.description"]}
        >
            <Tabs
                variant="fullWidth"
                small
                tabs={gnssTabs}
            />
        </Card>
    );
};

export default GnssInformation;