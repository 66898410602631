import React, { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface CheckboxCheckedIconProps {
    error?: boolean;
    disabled?: boolean;
    props?: SvgIconProps;
}
const CheckboxCheckedIcon = ({
    error,
    disabled,
    ...props
}: CheckboxCheckedIconProps) => {
    const {
        colors: { white, gray50, red700 },
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="7"
                fill="currentColor"
                stroke={white}
                strokeWidth="2"
            />

            {error && (
                <rect
                    x="2.5"
                    y="2.5"
                    width="19"
                    height="19"
                    rx="5.5"
                    stroke={red700}
                />
            )}

            <path
                d="M16.2857 12.5H7.71429C7.32143 12.5 7 12.1625 7 11.75C7 11.3375 7.32143 11 7.71429 11H16.2857C16.6786 11 17 11.3375 17 11.75C17 12.1625 16.6786 12.5 16.2857 12.5Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default CheckboxCheckedIcon;
